<template>
    <div>
        <van-search @input="searchArticle" style="padding:.1rem .2rem;border-radius: .3rem" v-model="value" placeholder="请输入搜索文章标题"/>
        <div>
            <div @click="turnTo(item.id,index)" style="display: flex;flex-direction: row;"
                 v-for="(item,index) in article_list" :key="index">
                <div class="topic-card"
                     style="font-size:.28rem;width:100%;display: flex;padding:.2rem .2rem;">
                    <div style="width:1.7rem;height:1.7rem;margin-right: .2rem;position: relative">
                        <img :src="item.share_cover"
                             style="top:0;width:100%;height:1.7rem;border-radius: .2rem;position: absolute"/>
                    </div>
                    <div style="flex:1;display: flex;flex-direction: column;justify-content: center">
                        <h4 class="text-resolve">
                            {{item.title.substring(0,3)}}
                        </h4>
                        <div class="text-resolve" style="margin: .05rem 0;">
                            {{item.share_desc}}
                            <!--                            基于字体的图标集，可以通过 Icon 组件使用，也可以在其他组件中通过 icon 属性引用。-->
                        </div>
                        <div style="color:#afafaf;font-size: .28rem;">
                            {{item.created_at.split(' ')[0]}}
                        </div>
                    </div>
                    <div @click.stop="ensureLove(item)">
                        <!--                        <van-icon name="like" size=".4rem" :color="bg"/>-->
                        <van-icon name="like" :color="item.is_liked===1?'red':'#dcdcdc'"/>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ArticleSearch",
        data(){
            return{
                value:'',
                article_list:'',
            }
        },
        methods:{
            searchArticle(e){
                this.articleList(e);
            },
            articleList(title) {
                this.$axios.get('miniprogram/articleList?page=1&size=10&title=' + title).then((res) => {
                    if (res.data.code === '0000') {
                        this.article_list = res.data.content.integral_articles.reverse();
                        // this.page=
                    }
                }, (err) => {
                    // alert(err)
                    throw new Error(err)
                });
            },
            turnTo(article_id, index) {
                this.$router.push('detail?article_id=' + article_id)
            }
        }
    }
</script>

<style scoped>
    .topic-card {
        border-radius: .2rem;
        margin-bottom: .2rem;
        background: white;
        box-shadow: 0 2px 3px #ebebeb;
    }

    .text-resolve {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .text-resolve1 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>
